@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
.product {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 480px;
	height: 150px;
	background-color: rgba(0, 0, 0, 0.1);
	margin-top: 50px;
	margin-left: 50px;
	margin-bottom: 10px;
	box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.product img {
	width: 200px;
	height: 150px;
}

.containereen {
	width: 600px;
	
	display: inline-block;
	vertical-align: top;
	margin-right: 25px;
}

.containertwee {
	width: 600px;
	
	display: inline-block;
	vertical-align: top;
}

.app-picture img {
	width: 145px;
	height: 200px;
	object-fit: contain;
	display: inline-block;
}


.description h3 {
	font-size: 24px;
	padding-right: 20px;
}

.description h5 {
	font-size: 18px;
	font-size: 200;
}

.button {
	font-size: 18px;
	padding: 10px;
	background-color: #556cd6;
	color: white;
	border: none;
	margin-left: 50px;
	width: 480px;
	box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.button:hover {
	cursor: pointer;
	opacity: 0.9;
}
:root {
  --primary: #bc4123;
  --secondary: #2b3452;
}
body {
  margin: 20;
  padding: 20px;
  font-family: "Poppins";
  background: var(--secondary);
}
* {
  color: #fff;
  margin: 0;
}
p {
  margin: 20px 0;
}
button {
  border: 0;
  padding: 8px;
  border-radius: 4px;
  color: white;
  background: var(--primary);
  cursor: pointer;
}

.container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 700px;
  margin: 0 auto;
}

.left-aligned {
  text-align: left;
}

textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}


.menu {
  list-style-type: none; /* Verwijder de standaard lijststijl */
  padding: 0;
  margin: 0;
  display: flex; /* Gebruik flexbox om de lijstitems horizontaal te rangschikken */
}

.menu li {
  margin-right: 20px; /* Voeg een beetje ruimte toe tussen de lijstitems */
}

.menu li:last-child {
  margin-right: 0; /* Verwijder de ruimte na het laatste item */
}

.menu a {
  text-decoration: none; /* Verwijder de standaard onderstreping van de links */
  color: #f0eaea; /* Stel de gewenste kleur in voor de links */
  padding: 10px 15px; /* Voeg wat opvulling toe aan de links */
  display: block; /* Zorg ervoor dat de links het volledige gebied van hun ouders vullen */
}

.rendermenu {
  list-style-type: none; /* Verwijder de standaard lijststijl */
  padding: 0;
  margin: 0;
  display: flex; /* Gebruik flexbox om de lijstitems horizontaal te rangschikken */
}

.rendermenu li {
  margin-right: 20px; /* Voeg een beetje ruimte toe tussen de lijstitems */
}

.rendermenu li:last-child {
  margin-right: 0; /* Verwijder de ruimte na het laatste item */
}

.rendermenu a {
  text-decoration: none; /* Verwijder de standaard onderstreping van de links */
  color: #000; /* Stel de gewenste kleur in voor de links */
  padding: 10px 15px; /* Voeg wat opvulling toe aan de links */
  display: block; /* Zorg ervoor dat de links het volledige gebied van hun ouders vullen */
}

/* navbar */
header nav {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  justify-content: flex-end;
  max-width: 1200px;
  margin: 0 auto;
}

header nav h1 {
  margin-right: auto;
  border-bottom: 3px solid var(--primary);
}

header nav a {
  text-decoration: none;
  padding: 6px;
  border-radius: 4px;
}

header nav a.active {
  background: var(--primary);
}

/* page content */


/* orofaciaal layout */
.orofaciaal-layout nav {
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  margin: 30px 0;
}
.orofaciaal-layout nav a{
  padding: 10px;
  border: 2px solid white;
  border-radius: 4px;
  text-decoration: none;
}
.orofaciaal-layout nav a:hover{
  border-color: var(--primary);
}
.orofaciaal-layout nav a.active {
  background: var(--primary);
}

/* training layout */
.training-layout nav {
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  margin: 30px 0;
}
.training-layout nav a{
  padding: 10px;
  border: 2px solid white;
  border-radius: 4px;
  text-decoration: none;
}
.training-layout nav a:hover{
  border-color: var(--primary);
}
.training-layout nav a.active {
  background: var(--primary);
}

/* modules layout */
.modules-layout nav {
  display: flex;
  justify-content: left;
  grid-gap: 30px;
  gap: 30px;
  margin: 30px 0;
}
.modules-layout nav a{
  padding: 10px;
  border: 2px solid white;
  border-radius: 4px;
  text-decoration: none;
}
.modules-layout nav a:hover{
  border-color: var(--primary);
}
.modules-layout nav a.active {
  background: var(--primary);
}

/* faq */
.faq .question {
  background: rgba(0,0,0,0.2);
  padding: 5px 20px;
  border-radius: 4px;
  margin: 20px 0;
}

/* contact form */
form {
  margin-top: 30px;
}
form input, form label span, form textarea {
  display: block;
}
form input, form textarea {
  margin-bottom: 30px;
  padding: 8px;
  border-radius: 4px;
  border: 0;
  width: 300px;
  color: var(--secondary);
}
form label span {
  margin-bottom: 10px;
}


/* careers list */
.careers > a {
  display: block;
  background: rgba(0,0,0,0.2);
  padding: 20px;
  border-radius: 4px;
  margin: 20px 0;
  text-decoration: none;
}
.careers > a:hover p {
  color: var(--primary);
}
.careers p {
  margin: 0;
}
.careers > a p:last-child {
 font-size: 0.8em;
 color: #bbb;
}


/* navbar */
header nav {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  justify-content: flex-end;
  max-width: 1200px;
  margin: 0 auto;
}

header nav h1 {
  margin-right: auto;
  border-bottom: 3px solid var(--primary);
}

header nav a {
  text-decoration: none;
  padding: 6px;
  border-radius: 4px;
}

header nav a.active {
  background: var(--primary);
}


